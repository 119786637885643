<template>
  <div>
    <Header />
    <v-app class="copyright-wrapped">
      <ScrollToTop />
      <div class="copyright-header">
        <small>{{ $t("expertise_uppercase") }} / {{ $t("services") }}</small>
        <h1>{{ $t("copyright") }}</h1>
      </div>
      <v-container class="copyright-body">
        <p>
          {{ $t("copyright_1") }}
        </p>
        <span><strong>{{ $t("copyright_bold") }}</strong></span>
        <ol type="a" class="mx-3">
          <li>
            {{ $t("copyright_line_2") }}
          </li>
          <li>{{ $t("copyright_line_3") }}</li>
          <li>
            {{ $t("copyright_line_4") }}
          </li>
          <li>{{ $t("copyright_line_5") }}</li>
          <li>
            {{ $t("copyright_line_6") }}
          </li>
          <li>
            {{ $t("copyright_line_7") }}
          </li>
          <li>{{ $t("copyright_line_8") }}</li>
          <li>{{ $t("copyright_line_9") }}</li>
          <li>{{ $t("copyright_line_10") }}</li>
          <li>{{ $t("copyright_line_11") }}</li>
          <li>{{ $t("copyright_line_12") }}</li>
          <li>{{ $t("copyright_line_13") }}</li>
          <li>{{ $t("copyright_line_14") }}</li>
          <li>
            {{ $t("copyright_line_15") }}
          </li>
          <li>
            {{ $t("copyright_line_16") }}
          </li>
          <li>
            {{ $t("copyright_line_17") }}
          </li>
          <li>
            {{ $t("copyright_line_18") }}
          </li>
          <li>{{ $t("copyright_line_19") }}</li>
          <li>{{ $t("copyright_line_20") }}</li>
        </ol>
        <span><strong>{{ $t("copyright_bold_2") }}</strong></span>
        <div class="align-center mb-1">
          <p class="ma-0">
            {{ $t("copyright_2_line_1") }}
          </p>
          <p class="ma-0">
            {{ $t("copyright_2_line_2") }}
          </p>
          <p class="ma-0">
            {{ $t("copyright_2_line_3") }}
          </p>
          <p class="ma-0">
            {{ $t("copyright_2_line_4") }}
          </p>
          <p class="ma-0">
            {{ $t("copyright_2_line_5") }}
          </p>
          <p class="ma-0">
            {{ $t("copyright_2_line_6") }}
          </p>
        </div>
        <br />
        <div class="d-flex align-center justify-content-center mt-5 mb-1">
          <div class="ma-0 text-center card pa-2">
            <strong>{{ $t("copyright_bold_3") }}</strong>
            <img src="~@/assets/alur-pendaftaran-hak-cipta.png" />
          </div>
        </div>
      </v-container>
    </v-app>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header-2.vue";
import Footer from "@/components/Footer.vue";
import ScrollToTop from "../../components/ScrollToTop.vue";

export default {
  name: "Copyright",
  components: {
    Header,
    Footer,
    ScrollToTop
  },
};
</script>

<style scoped>
@font-face {
  font-family: Steelfish;
  src: url("~@/assets/fonts/steelfish-rg.otf");
}

.copyright-wrapped {
  margin-top: 6.5em;
}

.copyright-header {
  background: url("~@/assets/copyright-bg.jpg") no-repeat;
  height: 15em;
  padding: 3em;
  background-color: #212121b3;
  color: #ffffff;
  background-blend-mode: soft-light;
  text-align: center;
  background-position-y: 50%;
}

h1 {
  font-family: "Steelfish", sans-serif;
  font-size: 7em;
}

.fa-angle-right {
  color: #8c3d40;
}

.copyright-body {
  padding: 3em;
  max-width: 75%;
  line-height: 2em;
}

@media screen and (max-width: 480px) {
  .copyright-body {
    max-width: 100%;
  }
}
</style>
